<template>
	<div class="pageContainer targetPage flexCloumn">
		<el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
			<div class="flexStart flex_1" style="flex-wrap:wrap;">

				<el-date-picker size="small" style="width:150px;margin-right:10px" v-model="searchForm.month"
					value-format="yyyy-MM" type="month" placeholder="选择月">
				</el-date-picker>
				<el-input placeholder="请输入关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
					style="width:300px" clearable>
					<el-select v-model="searchForm.ktype" slot="prepend" placeholder="请选择" style="width:120px">
						<el-option label="企业名称" value="enter_name"></el-option>
						<!-- <el-option label="学生姓名" value="stu_name"></el-option> -->

					</el-select>
				</el-input>

				<el-select v-model="searchForm.audit_status" clearable placeholder="请选择" size="small"
					style="width:130px;margin-left:10px">
					<el-option label="全部状态" value=""></el-option>

					<el-option label="待审核" :value="0"></el-option>
					<el-option label="审核通过" :value="1"></el-option>
					<el-option label="审核拒绝" :value="2"></el-option>
				</el-select>


				<tchtree v-model="searchForm.office_id"></tchtree>


				<el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary" @click="() => { page.current_page = 1; getList()}">
					搜索
				</el-button>



			</div>

		</el-row>
		<div class="dividerBar"></div>

		 <el-table v-tableFit :data="DataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
			<el-table-column type="index" width="55" label="序号" align="center">
			</el-table-column>

			<el-table-column prop="username" label="巡访教师" width="100" align="center"></el-table-column>
			<el-table-column prop="visit_date" label="巡访时间" width="200" align="center"></el-table-column>
			<el-table-column prop="enter_name" label="企业名称" show-overflow-tooltip min-width="200"
				align="center"></el-table-column>
			<el-table-column prop="stu_num" label="巡访学生数" width="120" align="center"></el-table-column>
			<el-table-column prop="username" label="学生姓名" show-overflow-tooltip width="280" align="center">
				<template slot-scope="scope">
					<span class="stuname" v-for="(s, i) in scope.row.stu_list" :key="s.id">
						{{ s.stu_name }}
					</span>



				</template>
			</el-table-column>


			<el-table-column label="发布日期" prop="create_date" min-width="200px" align="center">
				<!-- <template slot-scope="scope">
					{{ scope.row.create_date | handleDate }}
				</template> -->
			</el-table-column>

			<el-table-column prop="sp_status" fixed="right" label="状态" width="80" align="center">
				<template slot-scope="scope">
					<div v-if="scope.row.is_draft == 1" style="color: blue;">草稿</div>
					<div v-else>
						<el-tag size="mini" v-if="scope.row.audit_status == 2" type="danger">审核拒绝</el-tag>
						<el-tag size="mini" v-if="scope.row.audit_status == 1" type="success">审核通过</el-tag>
						<el-tag size="mini" v-if="scope.row.audit_status == 0" type="primary">待审核</el-tag>


					</div>

				</template>
			</el-table-column>





			<el-table-column fixed="right" label="操作" width="90" align="center">
				<template slot-scope="scope">

					<el-tag class="pointer" type="success" size="mini" @click="viewInfo(scope.row)"
						v-if="scope.row.audit_status == 0 && isGradeLeader">
						审批
					</el-tag>
					<el-tag class="pointer" v-else type="primary" size="mini" @click="viewInfo(scope.row)">详情</el-tag>


				</template>
			</el-table-column>
		</el-table>

		<el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
			:page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
			:page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
		</el-pagination>
		<formdialog v-if="dialogshow" :id="EditItem.id" :xunfang_bohui="xunfang_bohui"></formdialog>
	</div>
</template>

<script>
import formdialog from "./formdialogsp.vue"
import tchtree from '../com/tchTree.vue'
export default {
	components: {
		formdialog, tchtree
	},
	data() {
		return {
			temphead: "//school.mmteck.cn/static/temp.png",

			dialogshow: false,
			searchForm: {
				audit_status: "",
				ktype: "enter_name",
				keyword: "",
				class_id: "",
				month: ""

			},
			EditItem: {
				id: 0,
				group_name: "",
				needpic: "",
				outside: "",
				latesign: "",
			},
			DataList: [],
			listForm: [],
			listState: [],
			ClassList: [],
			ClassTree: [],
			defaultProps: {
				label: 'name',
				value: 'id',
				children: 'children',
				emitPath: false,
				checkStrictly: true
			},
			page: {
				count: 0,
				current_page: 1,
				per_page: 50,
				total_page: 0
			},
			xunfang_bohui: 0,//审批时是否开启驳回
		};
	},
	filters: {
		handleDate(value) {	// value是输入框的内容，也是要显示的内容
			if (value) {
				return value.substring(0, 10)
			}
		}
	},

	computed: {
		isGradeLeader() {
			if (this.$store.state.teacher.sys_roles) {
				let roles = this.$store.state.teacher.sys_roles
				let arr = roles.filter(r => r.role_code.indexOf('xzr') >= 0 || r.role_code == 'xfsp').map(r => r.role_code)
				return arr.length > 0
			}
			return 0
		},
	},
	mounted() {
		this.getConfig()
		this.getAllPickerVal()
		this.getOrgTree()
	},
	methods: {
		getOrgTree1() {
			this.$http.post("/api/vist_tch_cls_data").then(res => {
				res.data.map(e => {
					e.name = e.upname + e.name
				})
				this.ClassList = res.data

			})

		},
		getOrgTree() {
			this.$http.post("/api/teacher_class_tree").then(res => {
				this.ClassTree = res.data.tree
			})

		},
		getAllPickerVal() {
			//巡访形式&&巡访情况
			this.$http.post("/api/sys_param_list", { param_type: "'VISIT_FORM','VISIT_SITUATION'" }).then((res) => {
				let listForm = [], listState = [];
				res.data.map((item, index, arr) => {
					if (item.param_type == 'VISIT_FORM') {
						listForm.push(item)

					}
					if (item.param_type == 'VISIT_SITUATION') {
						listState.push(item)

					}
				})

				this.listForm = listForm
				this.listState = listState
				this.getList()

			})
		},
		//审批是否开启驳回
		getConfig() {
			this.$http.post("/api/sys_sch_config_list", { ktype: "ktype", keyword: 'xunfang_bohui' }).then(res => {
				if (res.data.data && res.data.data[0]) {
					this.xunfang_bohui = res.data.data[0].kvalue
				}

			})
		},
		getList() {
			this.$http.post("/api/teacher_visit_audit_list", {
				page: this.page.current_page,
				pagesize: this.page.per_page,
				month: this.searchForm.month,
				enter_name: this.searchForm.keyword,
				// class_id: this.searchForm.class_id,
				office_id: this.searchForm.office_id,
				audit_status: this.searchForm.audit_status,
				is_draft: 0



			}).then(res => {
				for (let item of res.data.data) {
					item.stu_list = JSON.parse(item.stu_list)
				}
				this.DataList = res.data.data
				this.page = res.data.page
			})
		},
		handlePageChange(page) {
			this.page.current_page = page
			this.getList()
		},
		handleSizeChange(val) {
			this.page.current_page = 1;
			this.page.per_page = val;
			this.getList()
		},
		addNew() {
			this.EditItem.id = 0;
			this.dialogshow = true;
		},
		viewInfo(row) {
			this.EditItem = row
			this.dialogshow = true;
		}
	},
}
</script>

<style scoped="scoped">
.spuser {
	display: inline-block;
	border: 1px solid rgb(255, 148, 62);


	padding: 2px;
	margin-right: 10px;
	margin-bottom: 5px;
	border-radius: 4px;
	padding: 4px 10px;
}

.spuser img {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
}
</style>
